import { useRoute, useRouteParams } from '#imports'

import type { CMSPageType } from '@backmarket/http-api/src/api-specs-content'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { DISCOUNT } from '~/scopes/discount/routes-names'
import { HOME } from '~/scopes/home/route-names'
import { ROUTES as PLP_ROUTES } from '~/scopes/product-list/route-names'

import { CMS } from '../routes-names'
import {
  CMS_PAGE_TYPE_BY_ROUTE_NAME,
  PAGE_NAME_BY_PLP_UUID,
} from '../service/content-service.config'

type PageParams = {
  pageType: CMSPageType
  pageName: string
  pageCategory?: string
}

function getPageNameByExperiment({
  pageName,
  experiments,
  experimentName,
}: {
  pageName: string
  experiments: ReturnType<typeof useExperiments>
  experimentName: string
}) {
  const variation = experiments[`experiment.${experimentName}`]

  if (!variation || variation === 'noVariation') return pageName

  return variation
}

function getPageNameByRouteName({
  route,
  routeParams,
  experiments,
}: {
  route: ReturnType<typeof useRoute>
  routeParams: ReturnType<typeof useRouteParams>
  experiments: ReturnType<typeof useExperiments>
}) {
  const { pageName } = routeParams

  switch (route.name) {
    case CMS.REFER_FRIEND:
      return 'refer-friend'
    case CMS.STATEMENT:
      return 'accessibility-statement'
    case DISCOUNT.MILITARY:
      return 'military-discount'
    case DISCOUNT.STUDENT:
      return 'student-discount'
    case HOME: {
      return getPageNameByExperiment({
        pageName: 'home',
        experiments,
        experimentName: 'homeCMSPageName',
      })
    }
    case CMS.EVENT: {
      if (pageName === 'recycling') {
        return getPageNameByExperiment({
          pageName,
          experiments,
          experimentName: 'recyclingEventPageName',
        })
      }
      if (pageName === 'good-deals') {
        return getPageNameByExperiment({
          pageName,
          experiments,
          experimentName: 'goodDealsEventPageName',
        })
      }
      if (pageName === 'holidays') {
        return getPageNameByExperiment({
          pageName,
          experiments,
          experimentName: 'holidaysEventPageName',
        })
      }

      return pageName
    }

    case CMS.BUYBACK: {
      if (pageName === 'home') {
        return getPageNameByExperiment({
          pageName,
          experiments,
          experimentName: 'homeBuybackPageName',
        })
      }

      return pageName
    }

    case PLP_ROUTES.UNIVERSE: {
      const { market } = useMarketplace()
      const uuid = Array.isArray(route.params.uuid)
        ? route.params.uuid.join('')
        : route.params.uuid
      const pageNames = PAGE_NAME_BY_PLP_UUID[market.countryCode]

      if (!pageNames)
        throw new Error(
          `No available page for this country ${market.countryCode}`,
        )

      return pageNames[uuid]
    }
    default:
      return pageName
  }
}

export function usePageParamsFromRoute(): PageParams {
  const route = useRoute()
  const routeName = String(route.name)

  const routeParams = useRouteParams()
  const { pageCategory } = routeParams
  const experiments = useExperiments()

  const pageType = CMS_PAGE_TYPE_BY_ROUTE_NAME[routeName]

  const pageName = getPageNameByRouteName({
    experiments,
    route,
    routeParams,
  })

  return {
    pageType,
    pageName,
    pageCategory,
  }
}
